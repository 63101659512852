import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import roundDone from "../images/round-done-24px.svg";
import roundClear from "../images/round-clear-24px.svg";
import { acceptJoinRequest, rejectJoinRequest } from "../api/apiClient";

export default class JoinRequestRow extends React.Component {
  render() {
    return (
      <InputGroup id={this.props.request.id + "-input-group"} className="mb-1">
        {this.props.isCreator && (
          <InputGroup.Prepend>
            <InputGroup.Text
              className="cursor-pointer btn btn-danger"
              id={this.props.request.id + "-input-prepend-text"}
              onClick={() => acceptJoinRequest(this.props.request)}
            >
              <img
                id={this.props.request.id + "-accept-icon"}
                alt="accept-button"
                src={roundDone}
              />
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Form.Control
          type="text"
          id={this.props.request.id + "-input"}
          value={this.props.request.username}
          disabled={true}
          className="text-center"
        />
        {this.props.isCreator && (
          <InputGroup.Append>
            <InputGroup.Text
              className="cursor-pointer btn btn-danger"
              id={this.props.request.id + "-delete"}
              onClick={() => rejectJoinRequest(this.props.request)}
            >
              <img
                id={this.props.request.id + "-reject-icon"}
                alt="reject-button"
                src={roundClear}
              />
            </InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    );
  }
}
