import React from "react";
import "./App.css";
import "firebase/auth";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import Login from "./sites/login";
import ProtectedRoute from "./auth/protectedRoute";
import Menu from "./sites/menu";
import { userAndIsLoadingAndSettingsAndError } from "./state/stateMappers";
import Settings from "./sites/settings";
import Create from "./sites/create";
import Join from "./sites/join";
import Voting from "./sites/voting";
import Instructions from "./sites/instructions";
import history from "./navigation/history";
import Error from "./sites/error";
import { defaultLoadingBar } from "./commons/loadingBar";
import { Col, Container, Row } from "react-bootstrap";
import { NO_SETTINGS } from "./state/reducers";
import { defaultLogo } from "./components/logo";
import Header from "./components/header";

class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        {this.props.user && <Header />}
        <div className="text-center text-md-center">{this.renderBody()}</div>
      </Router>
    );
  }

  renderBody() {
    if (
      this.props.isLoading ||
      (this.props.user &&
        this.props.settings === NO_SETTINGS &&
        this.props.error === "")
    ) {
      return (
        <div className="text-center text-md-center">
          <Container fluid>
            <Row>
              <Col />
              <Col className="text-center align-content-center align-self-center">
                {defaultLogo}
                {defaultLoadingBar}
              </Col>
              <Col />
            </Row>
          </Container>
        </div>
      );
    } else {
      return (
        <Container fluid>
          <Switch>
            <Route path="/" exact component={Menu} />
            <Route path="/login" exact component={Login} />
            <Route path="/instructions" exact component={Instructions} />
            <Route path="/error" exact component={Error} />
            <ProtectedRoute
              path="/settings"
              exact
              component={Settings}
              user={this.props.user}
            />
            <ProtectedRoute
              path="/create"
              exact
              component={Create}
              user={this.props.user}
            />
            <ProtectedRoute
              path="/join/:votingKey"
              exact
              component={Join}
              user={this.props.user}
            />
            <ProtectedRoute
              path="/join"
              exact
              component={Join}
              user={this.props.user}
            />
            <ProtectedRoute
              path="/voting/:votingId"
              component={Voting}
              user={this.props.user}
            />
          </Switch>
        </Container>
      );
    }
  }
}

const mapState = (state) => {
  return userAndIsLoadingAndSettingsAndError(state);
};

App = connect(mapState)(App);

export default App;
