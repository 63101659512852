import React from "react";
import {Redirect, Route, withRouter} from "react-router-dom";
import {userAndIsLoadingAndSettingsAndReturnPath} from "../state/stateMappers";
import {connect} from "react-redux";
import {setReturnPath} from "../state/actions";

class ProtectedRoute extends React.Component {

    render() {
        let Component = this.props.component;
        let path = this.props.path;
        let dispatch = this.props.dispatch;

        if (this.props.user) {
            return <Route
                exact
                path={path}
                render={props => {
                    return <Component {...props} />
                }
                }
            />
        } else {
            return <Route
                exact
                render={props => {
                    if (props.location.pathname !== "/login" && props.returnPath !== "") {
                        dispatch(setReturnPath(props.location.pathname));
                    }
                    return <Redirect

                        to={{
                            pathname: "/login",
                        }}
                    />
                }
                }
            />
        }

    }
}

const mapState = state => {
    return userAndIsLoadingAndSettingsAndReturnPath(state);
};

ProtectedRoute = withRouter(connect(mapState)(ProtectedRoute));

export default ProtectedRoute;