import React from 'react'
import VoteForTopic from "./voteForTopic";

export default class VotingTopicSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {iWantToChangeMyVote: false}
    }

    render() {
         return <div className="summary-topic">
                {this.prepareTopicSummary()}
            </div>;
    }

    prepareTopicSummary() {
        if (!this.props.votes || this.props.votes.length === 0) {
            return <div className="text-center">No votes for topic</div>
        }

        return this.props.votes.sort((a, b) => {
            let number = a.votingOption.order - b.votingOption.order;
            if (number === 0) {
                return a.username.localeCompare(b.username, "en", {sensitivity: 'base'})
            }
            return number;
        }).map(vote =>
            <VoteForTopic key={vote.id} vote={vote}/>
        )
    }


}