import React from "react";
import RedirectButton from "./redirectButton";
import ScrumVoteInput from "./scrumVoteInput";
import {
    closeVoting,
    createTopic,
    sendVote,
    updateTopic,
} from "../api/apiClient";
import {defaultLoadingBar} from "../commons/loadingBar";
import {ButtonGroup, Col, Container, ListGroup, Row} from "react-bootstrap";
import UserRow from "./userRow";
import ScrumVoteCheckbox from "./scrumVoteCheckbox";
import {userAndIsLoadingAndSettingsAndTokenAndVotingData} from "../state/stateMappers";
import {connect} from "react-redux";
import {setAutoSwitchToNewTopic, setCurrentTopicId} from "../state/actions";
import {svLog} from "../commons/logger";
import {DEFAULT_TOPIC_ID} from "../state/reducers";
import VotingTopicSummary from "./votingTopicSummary";
import JoinRequestList from "./joinRequestsList";

class InProgressVoting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iWantToChangeMyVote: false,
            topicName: "",
            closeVotingWarning: false,
            sendingVote: false,
            sendingVoteError: false
        };
    }

    render() {
        return (
            <>
                <Row>
                    <Col className="text-left">{this.renderTopicColumn()}</Col>
                    <Col className="main-column border-column text-left">
                        {this.renderMiddleColumn()}
                    </Col>
                    <Col className="text-left">
                        <Container fluid>
                            <Row>
                                <Col>
                                    <div className="h4 mb-4">
                                        <u>Users</u>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="min-vh-50">
                                <Col>
                                    <div className="h6 mb-2">Participants</div>
                                    {this.renderCurrentParticipantsPart()}
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="h6 mb-2">Join requests</div>
                                    <JoinRequestList
                                        joinRequests={this.props.joinRequests}
                                        isCreator={this.props.isCreator}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </>
        );
    }

    getCurrentTopic() {
        if (this.props.currentTopicId === DEFAULT_TOPIC_ID) {
            return this.props.votingTopics[0];
        }

        return this.props.votingTopics.find(
            (candidate) => candidate.id === this.props.currentTopicId
        );
    }

    renderTopicColumn() {
        let topicsPart = this.prepareTopics();

        return (
            <>
                <Row>
                    <Col>
                        <div className="h4 mb-4">
                            <u>Topics</u>
                        </div>
                        <div/>
                        {topicsPart}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col>{this.props.isCreator && this.prepareCreateTopicPart()}</Col>
                </Row>
            </>
        );
    }

    prepareTopics() {
        if (this.props.votingTopics.length === 0) {
            return (
                <div className="mb-2">
                    <span className="h4">No topics created yet</span>
                </div>
            );
        }

        return (
            <>
                <ScrumVoteCheckbox
                    id="auto-switch-topic-checkbox"
                    text="Auto switch to new topic"
                    name="autoSwitchToNewTopic"
                    checked={this.props.autoSwitchToNewTopic}
                    onChange={(event) => this.handleAutoSwitchToNewTopic(event)}
                    tooltip="If checked, new topics will be selected "
                />
                <ButtonGroup vertical className="mb-3 w-100">
                    {this.props.votingTopics.map((topic) => {
                        let variant = topic.id === this.getCurrentTopic().id ? "" : "light";
                        return (
                            <RedirectButton
                                key={topic.id + "-button-key"}
                                size="sm"
                                variant={variant}
                                text={topic.topic}
                                id={topic.id + "topicid"}
                                onClick={() => {
                                    this.props.dispatch(setCurrentTopicId(topic.id));
                                    this.props.loadVotingFunction();
                                }}
                            />
                        );
                    })}
                </ButtonGroup>
            </>
        );
    }

    handleAutoSwitchToNewTopic(event) {
        this.props.dispatch(setAutoSwitchToNewTopic(event.target.checked));
    }

    renderMiddleColumn() {
        let middleColumn;
        if (this.state.closeVotingWarning) {
            return this.renderCloseVotingWarning();
        }

        if (!this.getCurrentTopic()) {
            middleColumn = (
                <div className="text-center">
                    <div>Waiting for topic to vote</div>
                    {defaultLoadingBar}
                </div>
            );
        } else {
            if (this.isTopicOpen()) {
                middleColumn = (
                    <>
                        <div className="h4 mb-4">
                            <u>{this.getCurrentTopic().topic}</u>
                        </div>
                        {this.prepareOpenTopic()}
                        {this.props.isCreator && this.prepareCreatorButtons()}
                    </>
                );
            } else {
                middleColumn = (
                    <>
                        <div className="h4 mb-4">
                            <u>{this.getCurrentTopic().topic}</u>
                        </div>
                        <VotingTopicSummary
                            key={this.getCurrentTopic().id}
                            votingTopic={this.getCurrentTopic()}
                            votes={this.props.votes}
                        />
                        {this.props.isCreator && this.prepareCreatorButtons()}
                    </>
                );
            }
        }
        return middleColumn;
    }

    isTopicOpen() {
        return this.getCurrentTopic().status === "OPEN";
    }

    prepareCreateTopicPart() {
        return (
            <>
                <ScrumVoteInput
                    id="create-topic-input"
                    type="text"
                    value={this.state.topicName}
                    className="w-100"
                    onChange={(event) => this.handleTopicNameChange(event)}
                    placeholder="New topic..."
                    onKeyPress={() => this.localCreateTopic()}
                />
                <ButtonGroup vertical className="mt-0 w-100">
                    <RedirectButton
                        id="create-topic-button"
                        text="Create topic"
                        onClick={() => this.localCreateTopic()}
                    />
                </ButtonGroup>
            </>
        );
    }

    handleTopicNameChange(event) {
        this.setState({topicName: event.target.value});
    }

    prepareCreatorButtons() {
        return (
            <div className="text-center">
                <div/>
                <ButtonGroup vertical className="mt-5 mb-3 w-100">
                    {this.isTopicOpen() && (
                        <RedirectButton
                            id="close-topic-button"
                            text="Close topic"
                            onClick={() => this.closeTopic()}
                        />
                    )}
                    <RedirectButton
                        id="close-voting-button"
                        text="Close voting"
                        variant="danger"
                        onClick={() => this.setState({closeVotingWarning: true})}
                    />
                </ButtonGroup>
            </div>
        );
    }

    closeTopic() {
        let currentTopic = this.getCurrentTopic();
        currentTopic.status = "CLOSED";
        updateTopic(currentTopic).then();
    }

    localCreateTopic() {
        if (this.state.topicName && this.state.topicName !== "") {
            let topicToCreate = {
                topic: this.state.topicName,
                votingId: this.props.voting.id,
                status: "OPEN",
            };
            createTopic(topicToCreate).then(() => this.setState({topicName: ""}));
        }
    }

    renderCloseVotingWarning() {
        return (
            <div className="text-center">
        <span id="close-voting-warning-text">
          Are you sure you want to close your voting?
        </span>
                <ButtonGroup vertical className="mt-4 mb-3 w-75">
                    <RedirectButton
                        id="close-voting-confirm-button"
                        text="Close voting"
                        variant="danger"
                        classNames="button-warning"
                        onClick={() => this.localCloseVoting()}
                    />
                    <RedirectButton
                        id="close-voting-cancel-button"
                        text="Cancel"
                        onClick={() => this.setState({closeVotingWarning: false})}
                    />
                </ButtonGroup>
            </div>
        );
    }

    localCloseVoting() {
        closeVoting(this.props.voting.id)
            .then(() => this.props.loadVotingFunction())
            .then(() => this.setState({closeVotingWarning: false}));
    }

    renderCurrentParticipantsPart() {
        let votedUserIds = this.props.votes.map((vote) => vote.userId);
        let userRows = this.props.voting.participants.map((participant, index) => {
            return (
                <UserRow
                    voted={votedUserIds.includes(participant.userId)}
                    key={participant.userId}
                    index={index}
                    creatorMode={this.props.isCreator}
                    participant={participant}
                    voting={this.props.voting}
                />
            );
        });
        return (
            <ListGroup as="ul" className="text-left">
                {userRows}
            </ListGroup>
        );
    }

    prepareOpenTopic() {
        let myVote = this.findMyVote();
        return (
            <>
                {myVote && (
                    <div className="text-center">
                        You voted: {myVote.votingOption.value}
                    </div>
                )}
                {this.props.voting && this.prepareVoteButtons(myVote)}
            </>
        );
    }

    findMyVote() {
        return this.props.votes.find((vote) => vote.userId === this.props.userId);
    }

    vote(option) {
        this.setState({sendingVote: true});
        let voteToSave = {
            topicId: this.getCurrentTopic().id,
            votingOption: option,
        };
        sendVote(voteToSave).then((json) => {
            if (!json) {
                svLog("Unable to vote");
                this.setState({sendingVoteError: true})
            } else {
                this.setState({sendingVoteError: false})
            }
        }).catch(e => {
            this.setState({sendingVoteError: true})
        }).finally(() => this.setState({sendingVote: false}));
    }

    prepareVoteButtons(myVote) {
        let votingOptions = this.props.voting.config.votingOptions;
        if (this.state.sendingVote) {
            return <div className="text-center">
                {defaultLoadingBar}
                <br/>
                <span>Sending vote...</span>
            </div>;
        }
        if (!myVote || this.state.iWantToChangeMyVote) {
            let buttons = votingOptions
                .sort((a, b) => a.order - b.order)
                .map((option) => (
                    <RedirectButton
                        id={"vote-value-number-" + option.order}
                        key={option.order}
                        text={option.value}
                        onClick={() => {
                            this.vote(option);
                            this.setState({iWantToChangeMyVote: false});
                        }}
                    />
                ));
            return (
                <><ButtonGroup vertical className="w-100">
                    {buttons}

                </ButtonGroup>
                    <div className="text-center warning">
                    {this.state.sendingVoteError && <span>Unexpected error please vote again, if this reappear please refresh the page</span>}
                    </div>
                    </>
            );
        } else {
            return (
                <div className="text-center">
                    <ButtonGroup vertical className="w-75">
                        <RedirectButton
                            id="change-vote-button"
                            text="Change my vote"
                            onClick={() => this.setState({iWantToChangeMyVote: true})}
                        />
                    </ButtonGroup>
                </div>
            );
        }
    }
}

const mapState = (state) => {
    return userAndIsLoadingAndSettingsAndTokenAndVotingData(state);
};

InProgressVoting = connect(mapState)(InProgressVoting);

export default InProgressVoting;
