import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

export default class ScrumVoteCheckbox extends React.Component {
  render() {
    if (!this.props.id || this.props.id === "") {
      throw Error("Every ScrumVoteInput should have a proper id");
    }

    return (
      <OverlayTrigger
        key={this.props.id + "-tooltip"}
        placement="right"
        overlay={
          <Tooltip id={`tooltip-${this.props.id + "voted"}`}>
            {this.props.tooltip}
          </Tooltip>
        }
      >
        <Form>
          <Form.Check
            id={this.props.id}
            type="switch"
            checked={this.props.checked}
            onChange={this.props.onChange}
            name={this.props.text}
            label={this.props.text}
          />
        </Form>
      </OverlayTrigger>
    );
  }
}
