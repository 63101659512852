import React from 'react'
import VotingTopicSummary from "../components/votingTopicSummary";
import {Col, Row} from "react-bootstrap";

export default class VotingSummary extends React.Component {

    render() {

        let topics = this.renderTopics();

        return <>
            <div className="h4">Voting summary</div>
            {topics}
        </>
    }

    renderTopics() {
        if (this.props.summary.topics.length === 0) {
            return <div>There are no topics for this voting</div>
        }

        return this.props.summary.topics.map(topicSummary => {
            return <Row key={topicSummary.topic.id + 'topic-row-keyi'}>
                <Col/>
                <Col>
                    <div className="border-bottom">
                        <div className="h4">{topicSummary.topic.topic}</div>
                        <VotingTopicSummary key={topicSummary.topic.id}
                                            votingTopic={topicSummary.topic}
                                            votes={topicSummary.votes}/>
                    </div>
                </Col>
                <Col/>
            </Row>
        })
    }
}