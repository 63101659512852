import React from 'react'
import {connect} from "react-redux";
import {userAndIsLoadingAndSettings} from "../state/stateMappers";
import {withRouter} from "react-router-dom";
import RedirectButton from "../components/redirectButton";
import ScrumVoteInput from "../components/scrumVoteInput";
import {CHOOSE_OTHER_NAME, createVotingApi, saveSettingsApi, TO_MANY_VOTINGS} from "../api/apiClient";
import {loadSettings} from "../auth/auth";
import ScrumVoteCheckbox from "../components/scrumVoteCheckbox";
import {svLog} from "../commons/logger";
import {Alert, ButtonGroup, Col, Row} from "react-bootstrap";
import VotingOptions from "../components/votingOptions";

class Create extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            votingName: "",
            popupMessage: "",
            isPopupOpen: false,
            warning: "",
            autoCloseTopic: this.props.settings.config.votingSettings.autoCloseTopic,
            autoJoinVoting: this.props.settings.config.votingSettings.autoJoinVoting,
            voteOptions: this.props.settings.config.votingOptions
        };
        this.handleVotingNameChange = this.handleVotingNameChange.bind(this);
        this.handleAutoCloseTopic = this.handleAutoCloseTopic.bind(this);
        this.handleAutoJoinVoting = this.handleAutoJoinVoting.bind(this);
    }

    componentDidMount() {
        loadSettings().then();
    }

    render() {
        return <Row className="mt-3">
            <Col/>
            <Col className="main-column">

                <div>
                    <span className="h4">Details of new voting</span>
                    <ScrumVoteInput id='voting-name-input' type="text" value={this.state.votingName}
                                    onChange={this.handleVotingNameChange} className="mt-3"
                                    placeholder="Voting name..." onKeyPress={() => this.onCreate()}/>
                    <div className="mt-1 mb-4">
                        <div>Voting settings:</div>
                        <ScrumVoteCheckbox id='auto-close-topic-checkbox' text="Auto close topic"
                                           name="autoCloseTopic"
                                           checked={this.state.autoCloseTopic}
                                           onChange={this.handleAutoCloseTopic}
                                           tooltip="If checked, topic will be closed when all participants will vote."/>
                        <ScrumVoteCheckbox id='auto-join-voting-checkbox' text="Auto join voting"
                                           name="autoJoinVoting"
                                           checked={this.state.autoJoinVoting}
                                           onChange={this.handleAutoJoinVoting}
                                           tooltip="If checked, all users will be added automatically to your voting."/>
                    </div>

                    <div className="mt-2"><span>Vote options for this voting: </span></div>
                    <VotingOptions voteOptions={this.state.voteOptions} updateVoteOptions={(newOptions) =>
                        this.setState({voteOptions: newOptions})
                    }/>
                    <ButtonGroup className="mt-2 mb-3 w-100">
                        <RedirectButton id='reset-options-button' text="Reset options to default"
                                        onClick={() => this.setState({voteOptions: this.props.settings.config.votingOptions})}/>
                        <RedirectButton id='save-options-button' text="Save options as default"
                                        onClick={() => this.saveSettingsAsDefault()}/>
                    </ButtonGroup>
                    {this.state.warning !== "" &&
                    <Alert className="mt-2 w-100" variant="danger"><span>{this.state.warning}</span></Alert>}
                    <div/>
                    <ButtonGroup vertical className="mt-2 mb-3 w-100">
                        <RedirectButton id='create-button' text="Create" onClick={() => this.onCreate()}/>
                    </ButtonGroup>

                </div>

            </Col>
            <Col/>
        </Row>;
    }

    onCreate() {

        if (this.state.votingName === "") {
            this.setState({warning: "Voting name should not be empty"});
            return;
        }
        if (!this.areOptionsValid()) {
            return;
        }

        let config = this.createConfig();
        let voting = {
            name: this.state.votingName,
            creatorUsername: this.props.settings.username,
            config: config
        };
        createVotingApi(voting).then((result) => {
            if (result) {
                if (result === TO_MANY_VOTINGS) {
                    this.setState({warning: "You have created to many voting sessions for this user. Wait few hours to continue..."});
                } else if (result === CHOOSE_OTHER_NAME) {
                    this.setState({warning: "Please choose other voting name..."})
                } else {
                    this.props.history.push(`/voting/${result.id}`);
                }
            }
        });

    }

    handleVotingNameChange(event) {
        this.setState({votingName: event.target.value});
    }

    handleAutoCloseTopic(event) {
        this.setState({autoCloseTopic: event.target.checked});
    }

    handleAutoJoinVoting(event) {
        this.setState({autoJoinVoting: event.target.checked});
    }

    createConfig() {
        return {
            votingOptions: this.state.voteOptions.filter(option => option.value !== ""),
            votingSettings: {
                autoCloseTopic: this.state.autoCloseTopic,
                autoJoinVoting: this.state.autoJoinVoting
            }
        };
    }

    saveSettingsAsDefault() {
        if (this.areOptionsValid()) {
            let settings = this.props.settings;
            settings.config = this.createConfig();
            saveSettingsApi(settings).then((response) => {
                svLog("saveSettingsAsDefault response:" + response);
                loadSettings()
            });
        }
    }

    areOptionsValid() {
        let isValid = this.state.voteOptions.length >= 3;
        if (!isValid) { // 3 because the last one is empty and will be trimmed
            this.setState({warning: "There should be at least two vote options"});
        }
        return isValid;
    }
}

Create = withRouter(connect(userAndIsLoadingAndSettings)(Create));

export default Create;