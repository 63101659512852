import React from "react";
import "firebase/auth";
import { connect } from "react-redux";
import {
  loginFirebaseAnonymously,
  loginFirebaseWithGoogle,
} from "../auth/auth";
import LoginButton from "../components/loginButton";
import { userAndIsLoadingAndReturnPath } from "../state/stateMappers";
import { Redirect, withRouter } from "react-router-dom";
import { baseCookiePolicyPath, basePrivacyPolicyPath } from "../api/globalConf";
import googleLogo from "../images/googleLoginLogo.svg";
import anonymousLogo from "../images/auth_service_anonymous.svg";
import ScrumVoteInput from "../components/scrumVoteInput";
import { svLog } from "../commons/logger";
import { Alert, Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { defaultLogo } from "../components/logo";

class Login extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { chosenUserType: ChosenUserType.NONE, username: "" };
  }

  render() {
    if (this.props.user) {
      let path =
        this.props.returnPath.path !== "" ? this.props.returnPath.path : "/";

      return (
        <Redirect
          to={{
            pathname: path,
          }}
        />
      );
    }

    return this.renderBody();
  }

  renderBody() {
    return (
      <Row className="mt-1">
        <Col />
        <Col className="main-column">
          {defaultLogo}
          <div />
          {this.renderState()}
        </Col>
        <Col />
      </Row>
    );
  }

  renderState() {
    switch (this.state.chosenUserType) {
      case ChosenUserType.ANONYMOUS:
      case ChosenUserType.GOOGLE:
        return this.renderWarning();
      case ChosenUserType.NONE:
      default:
        return this.renderChooseLogin();
    }
  }

  renderChooseLogin() {
    return (
      <>
        <span className="w-100">
          To use ScrumVote you should choose login option
        </span>
        <br />
        <span className=" w-100 h4">Choose you login option</span>
        <ButtonGroup vertical className="w-100 mt-3 ">
          <LoginButton
            id="google-login-button"
            logo={googleLogo}
            onClick={() =>
              this.setState({ chosenUserType: ChosenUserType.GOOGLE })
            }
            text="Google"
          />
          <LoginButton
            id="anonymous-login-button"
            logo={anonymousLogo}
            onClick={() =>
              this.setState({ chosenUserType: ChosenUserType.ANONYMOUS })
            }
            text="Anonymous"
          />
        </ButtonGroup>
      </>
    );
  }

  isAnonymous() {
    return this.state.chosenUserType === ChosenUserType.ANONYMOUS;
  }

  renderWarning() {
    return (
      <>
        <Row className="w-100">
          <span className="h4 mb-3">
            By logging in you are accepting the app{" "}
            <a
              href={basePrivacyPolicyPath}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "var(--textInputColor)" }}
            >
              privacy policy
            </a>{" "}
            and{" "}
            <a
              href={baseCookiePolicyPath}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "var(--textInputColor)" }}
            >
              cookie policy
            </a>
            .
          </span>
        </Row>

        {this.isAnonymous() && (
          <>
            <Row className="w-100">
              <Alert variant="danger" className="w-100">
                By using anonymous login you may lose access to your account in
                the future or if you log out during a voting session
              </Alert>
              <div className="w-100 mt-3">
                Username will be visible for other users:
              </div>

              {this.state.showEmptyUserWarning && (
                <Alert
                  id="empty-username-warning"
                  variant="warning"
                  className="w-100"
                >
                  Username cannot be empty
                </Alert>
              )}
            </Row>
            <Row className="w-100">
              <ScrumVoteInput
                id="anonymous-username-input"
                type="text"
                value={this.state.username}
                onChange={(event) =>
                  this.setState({ username: event.target.value })
                }
                className="w-100"
                placeholder="Username..."
                onKeyPress={() => this.handleAccept()}
              />
            </Row>
          </>
        )}

        <Row className="w-100">
          <ButtonGroup className="w-100 align-self-center">
            <Button
              id="accept-terms-button"
              className="accept-popup-button popup-button"
              onClick={() => this.handleAccept()}
            >
              Agree
            </Button>
            <Button
              id="cancel-terms-button"
              className="cancel-popup-button popup-button"
              variant="danger"
              onClick={() =>
                this.setState({ chosenUserType: ChosenUserType.NONE })
              }
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Row>
      </>
    );
  }

  handleAccept() {
    if (this.isAnonymous()) {
      if (this.state.username !== "") {
        loginFirebaseAnonymously(this.state.username);
      } else {
        this.setState({ showEmptyUserWarning: true });
      }
    } else {
      svLog("Using google auth");
      loginFirebaseWithGoogle();
    }
  }
}

const ChosenUserType = {
  NONE: "none",
  GOOGLE: "google",
  ANONYMOUS: "anonymous",
};

const mapState = (state) => {
  return userAndIsLoadingAndReturnPath(state);
};

Login = withRouter(connect(mapState)(Login));

export default Login;
