import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {exportError} from "../state/stateMappers";
import SmallLogo from "../components/smallLogo";
import {isDev} from "../api/globalConf";
import {Col, Row} from "react-bootstrap";

//TODO this proably should be removed as a site and handled in the App.js before the router as it is done with loading
class Error extends React.Component {

    render() {
        return <Row>
            <Col/>
            <Col>
                <div>
                    <SmallLogo/>
                    <span className="warning small-text">There was some unexpected error... </span>
                    {isDev && <><br/><span className="warning">{this.props.error}</span></>}
                    <div/>
                    <span>Clear cookies for this site and try again</span>
                </div>
            </Col>
            <Col/>
        </Row>

    }
}

Error = withRouter(connect(exportError)(Error));

export default Error;