import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import deleteIcon from "../images/round-delete_forever-24px.svg";

export default class VoteOptionButton extends React.Component {
  render() {
    if (!this.props.id || this.props.id === "") {
      throw Error("Every VoteOptionButton should have a proper id");
    }
    return (
      <InputGroup id={this.props.id + "-input-group"} className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text id={this.props.id + "-input-prepend-text"}>
            {this.props.order}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type={this.props.type}
          id={this.props.id + "-input"}
          value={this.props.value}
          onChange={this.props.onChange}
          placeholder="New value..."
          className="text-center"
        />
        <InputGroup.Append>
          <InputGroup.Text
            className="cursor-pointer btn btn-danger"
            id={this.props.id + "-delete"}
            onClick={this.props.onDelete}
          >
            <img
              id={this.props.id + "-delete-icon"}
              alt="remove-button"
              src={deleteIcon}
            />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}
