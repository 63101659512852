import { actionEvent, apiRequestError } from "./breadcrumbs";
import { getCurrentToken, logoutFirebase } from "../auth/auth";
import { baseApiPath } from "./globalConf";
import { svLog } from "../commons/logger";

export const UNABLE_TO_LOAD_JOIN_REQUEST = "UNABLE_TO_LOAD_JOIN_REQUEST";
export const UNABLE_TO_CREATE_JOIN_REQUEST = "UNABLE_TO_CREATE_JOIN_REQUEST";
export const UNABLE_TO_CREATE_SETTINGS = "UNABLE_TO_CREATE_SETTINGS";
export const TO_MANY_VOTINGS = "TO_MANY_VOTINGS";
export const CHOOSE_OTHER_NAME = "CHOOSE_OTHER_NAME";
export const UNABLE_TO_LOAD_SETTINGS = "Unable to load Settings";

const basePath = baseApiPath;
const settingsUrl = "/settings";
const votingUrl = "/voting";
const joinRequestUrl = "/joinRequest";
const findByVoting = "/findByVoting";
const startUrl = "/start/";
const closeUrl = "/close/";
const vote = "/vote";
const votingTopic = "/votingTopic";
const summaryUrl = "/summary/";

const deleteUser = "/deleteUser";

export const votingSocketBasePath = (basePath + "/voting-ws").replace('http', 'ws');
const POST = "POST";
const PUT = "PUT";
const GET = "GET";
const PATCH = "PATCH";

const DELETE = "DELETE";

export async function createSettings(dataToSave) {
  actionEvent("createSettings");
  let settingsString = JSON.stringify(dataToSave);
  let object = {
    method: POST,
    headers: await getHeaders(),
    body: settingsString,
  };
  return fetchSV(basePath + settingsUrl, object)
    .then((response) => {
      actionEvent("settingsCreated");
      return response;
    })
    .catch(() => {
      throw Error(UNABLE_TO_CREATE_SETTINGS);
    });
}

export async function getSettingsApi() {
  return fetchSV(basePath + settingsUrl, {
    method: GET,
    headers: await getHeaders(),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 401) {
        logoutFirebase();
      } else {
        return UNABLE_TO_LOAD_SETTINGS;
      }
    })
    .catch(() => {
      return UNABLE_TO_LOAD_SETTINGS;
    });
}

export async function saveSettingsApi(dataToSave) {
  let settingsString = JSON.stringify(dataToSave);
  let object = {
    method: PUT,
    headers: await getHeaders(),
    body: settingsString,
  };
  return fetch(basePath + settingsUrl, object).then((response) => {
    actionEvent("settingsSaved");
    return response;
  });
}

export async function createVotingApi(dataToSave) {
  let dataAsString = JSON.stringify(dataToSave);
  actionEvent("createVoting");
  return fetch(basePath + votingUrl, {
    method: POST,
    headers: await getHeaders(),
    body: dataAsString,
  }).then((response) => {
    if (response.status === 201) {
      return response.json();
    } else if (response.status === 429) {
      return TO_MANY_VOTINGS;
    } else if (response.status === 409) {
      return CHOOSE_OTHER_NAME;
    }
  });
}

export async function createJoinRequest(dataToSave) {
  let dataAsString = JSON.stringify(dataToSave);
  actionEvent("joinRequestCreated");
  return fetch(basePath + joinRequestUrl, {
    method: POST,
    headers: await getHeaders(),
    body: dataAsString,
  }).then((response) => {
    if (response.status === 201) {
      return response.json();
    } else {
      return undefined;
    }
  });
}

export async function getJoinRequest(joinRequestId) {
  return fetch(basePath + joinRequestUrl + "/" + joinRequestId, {
    method: GET,
    headers: await getHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      apiRequestError(response.status, "cannot get join request");
      return UNABLE_TO_LOAD_JOIN_REQUEST;
    }
  });
}

export async function getJoinRequestForVoting(votingId) {
  let queryParam = `?votingId=${encodeURIComponent(votingId)}`;
  return fetch(basePath + joinRequestUrl + findByVoting + queryParam, {
    method: GET,
    headers: await getHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
  });
}

export function acceptJoinRequest(joinRequest) {
  joinRequest.status = "accepted";
  patchJoinRequest(joinRequest);
  actionEvent("acceptJoinRequest");
}

export function rejectJoinRequest(joinRequest) {
  joinRequest.status = "rejected";
  patchJoinRequest(joinRequest);
  actionEvent("rejectJoinRequest");
}

async function patchJoinRequest(joinRequest) {
  actionEvent("patchJoinRequest");
  let dataAsString = JSON.stringify(joinRequest);
  fetch(basePath + joinRequestUrl, {
    method: PATCH,
    headers: await getHeaders(),
    body: dataAsString,
  }).then((response) => {
    if (response.status !== 200) {
      apiRequestError(response.status, "unable to patch joinRequest");
    }
  });
}

export async function getVoting(votingId) {
  return fetch(basePath + votingUrl + "/" + votingId, {
    method: GET,
    headers: await getHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    return {};
  });
}

export async function startVoting(votingId) {
  actionEvent("startVoting");
  return fetch(basePath + votingUrl + startUrl + votingId, {
    method: PATCH,
    headers: await getHeaders(),
  });
}

export async function closeVoting(votingId) {
  actionEvent("closeVoting");
  fetch(basePath + votingUrl + closeUrl + votingId, {
    method: PATCH,
    headers: await getHeaders(),
  }).then((response) => {
    if (response.status !== 200) {
      const message = "Cannot start voting";
      apiRequestError(response.status, message);
    } else {
      actionEvent("votingClosed");
    }
  });
}

export async function getVotesForTopic(topicId) {
  let queryParam = `/?votingTopicId=${encodeURIComponent(topicId)}`;
  return fetch(basePath + vote + queryParam, {
    method: GET,
    headers: await getHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    return undefined;
  });
}

export async function sendVote(voteToSave) {
  actionEvent("sendVote");
  let voteToSaveString = JSON.stringify(voteToSave);
  return fetchSV(basePath + vote, {
    method: POST,
    headers: await getHeaders(),
    body: voteToSaveString,
  }).then((response) => {
      return response.json();
  });
}

export async function createTopic(topicToCreate) {
  let topicToCreateString = JSON.stringify(topicToCreate);
  fetch(basePath + votingTopic, {
    method: POST,
    headers: await getHeaders(),
    body: topicToCreateString,
  }).then((response) => {
    if (response.status !== 201) {
      const message = "Something went wrong while creating topic. Try again";
      apiRequestError(response.status, message);
    } else {
      actionEvent("topicCreated");
    }
  });
}

export async function updateTopic(topicToUpdate) {
  let topicToUpdateString = JSON.stringify(topicToUpdate);
  fetch(basePath + votingTopic, {
    method: PATCH,
    headers: await getHeaders(),
    body: topicToUpdateString,
  }).then((response) => {
    if (response.status !== 200) {
      const message = "Something went wrong while updating topic. Try again";
      apiRequestError(response.status, message);
    } else {
      actionEvent("topicUpdated");
    }
  });
}

export async function getSettings() {
  return fetch(basePath + settingsUrl, {
    method: GET,
    headers: await getHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else if (response.status === 401) {
      logoutFirebase();
    }
  });
}

export async function loadSummary(votingId) {
  actionEvent("loadSummary");
  return fetch(basePath + summaryUrl + votingId, {
    method: GET,
    headers: await getHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
  });
}

export async function callDeleteUser() {
  actionEvent("callDeleteUser");
  return fetch(basePath + deleteUser, {
    method: DELETE,
    headers: await getHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      actionEvent("deleteUser");
      return true;
    } else {
      return false;
    }
  });
}

export async function removeUserFromVoting(votingId, participantId) {
  return fetchSV(
    basePath + `/voting/${votingId}/update/participant/${participantId}`,
    {
      method: DELETE,
      headers: await getHeaders(),
    }
  ).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
  });
}

export async function getVotingTopics(votingId) {
  let url = basePath + `/allTopicForVoting/${votingId}`;
  return fetchSV(url, {
    method: GET,
    headers: await getHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
  });
}

function fetchSV(url, args, methodName= "general") {
  return fetch(url, args)
    .catch((e) => {
      svLog(e);
      actionEvent(`${methodName}MethodApiError`)
      throw Error("Some reason");
    })
    .then((response) => {
      if (response && !response.ok) {
        svLog(response.status + " code at url: " + url);
        actionEvent(`${methodName}MethodApiError`)
        throw Error(response.statusText);
      }
      return response;
    });
}

async function getHeaders() {
  let token = await getCurrentToken().token;
  while (token === undefined) {
    token = await getCurrentToken().token;
  }
  // TODO think about the version
  // let version = DeviceInfo.getVersion();
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token,
  };
}
