import React from "react";
import {userAndIsLoadingAndSettings} from "../state/stateMappers";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ScrumVoteInput from "../components/scrumVoteInput";
import RedirectButton from "../components/redirectButton";
import {callDeleteUser, saveSettingsApi} from "../api/apiClient";
import {loadSettings, logoutFirebase} from "../auth/auth";
import Popup from "reactjs-popup";
import headerIcon from "../images/headerIcon.png"
import {ButtonGroup, Col, Row} from "react-bootstrap";
import VotingOptions from "../components/votingOptions";
import ScrumVoteCheckbox from "../components/scrumVoteCheckbox";

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            voteOptions: [],
            warning: "",
            isPopupOpen: false,
            popupMessage: this.confirmationDeleteText,
            anonymous: true,
            redirect: false,
            autoCloseTopic: this.props.settings.config.votingSettings.autoCloseTopic,
            autoJoinVoting: this.props.settings.config.votingSettings.autoJoinVoting
        };
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
    }

    componentDidMount() {
        this.setState({username: this.props.settings.username});
        this.setState({anonymous: this.props.settings.anonymous});
        this.setState({voteOptions: this.props.settings.config.votingOptions.sort((a, b) => a.order - b.order)});
    }

    handleUsernameChange(e) {
        this.setState({username: e.target.value})
    }

    render() {
        return <Row>
            <Col/>
            <Col className="main-column">

                <div className="mt-3">
                    <span className="h4">Account settings</span>
                    <div/>
                    <ScrumVoteInput id='settings-username-input' type="text" value={this.state.username}
                                    className="mt-3"
                                    onChange={this.handleUsernameChange}
                                    placeholder="Username..."/>
                </div>
                <div className="">Voting settings:</div>
                <div className="mb-4">
                    <ScrumVoteCheckbox id='auto-close-topic-checkbox' text="Auto close topic"
                                       name="autoCloseTopic"
                                       checked={this.state.autoCloseTopic}
                                       onChange={(e) => this.handleAutoCloseTopic(e)}
                                       tooltip="If checked, topic will be closed when all participants will vote."/>
                    <ScrumVoteCheckbox id='auto-join-voting-checkbox' text="Auto join voting"
                                       name="autoJoinVoting"
                                       checked={this.state.autoJoinVoting}
                                       onChange={(e) => this.handleAutoJoinVoting(e)}
                                       tooltip="If checked, all users will be added automatically to your voting."/>
                </div>
                <div>Voting options:</div>
                <VotingOptions voteOptions={this.state.voteOptions} updateVoteOptions={(newOptions) =>
                    this.setState({voteOptions: newOptions})
                }/>
                {this.state.warning !== "" && <span className="warning small-text">{this.state.warning}</span>}

                <ButtonGroup vertical className="mt-4 mb-3 w-100">
                    <RedirectButton id='save-settings-button' text="Save" onClick={() => this.saveSettings()}/>
                    <RedirectButton id='delete-account-button' text="Delete Account" variant="danger"
                                    onClick={() => this.openPopup()}/>
                </ButtonGroup>
            </Col>
            <Col/>
        </Row>
    }

    saveSettings() {
        let filteredOptions = this.state.voteOptions.filter(option => option.value !== "");
        let validateResult = this.validateSettings(filteredOptions);
        if (validateResult) {
            this.setState({warning: validateResult});
        } else {
            let newSettings = {
                id: this.props.settings.id,
                username: this.state.username,
                config: {
                    votingOptions: filteredOptions,
                    votingSettings: {
                        autoCloseTopic: this.state.autoCloseTopic,
                        autoJoinVoting: this.state.autoJoinVoting
                    }
                }
            };
            saveSettingsApi(newSettings).then(response => {
                if (response.status === 200) {
                    loadSettings();
                    this.props.history.push(this.props.globalReturnPath.path);
                } else {
                    this.setState({warning: "Something went wrong while saving data"});
                }
            });
        }
    }

    validateSettings(filteredOptions) {
        if (this.state.username === "") {
            return "Username should not be empty";
        } else if (filteredOptions.length < 2) {
            return "There should be at least 2 voting options";
        }
        return undefined;
    }

    getPopup() {
        return <Popup open={this.state.isPopupOpen}
                      closeOnDocumentClick={false}
                      contentStyle={{background: "var(--mainBackGroundColor)", border: "1px solid rgb(0, 0, 0)"}}
                      onClose={() => this.closePopup()}>
            <div className="no-select">
                <img className="header-icon" src={headerIcon} alt="headerIcon"
                     onClick={() => this.props.history.push("/")}/>
            </div>
            {this.renderPopupMessage()(this)}
        </Popup>;
    }

    renderPopupMessage() {
        return this.state.popupMessage
    }

    closePopup() {
        this.setState({isPopupOpen: false});
    }

    openPopup() {
        this.setState({isPopupOpen: true});
    }

    resetPopup() {
        this.setState({popupMessage: this.confirmationDeleteText})
    }

    advancePopup(success) {
        if (success) {
            this.setState({popupMessage: this.successDeleteText})
        } else {
            this.setState({popupMessage: this.failureDeleteText})
        }
    }

    confirmationDeleteText = function (obj) {
        return <div className="login-popup-text">
            Are you sure you would like to delete your ScrumVote account?
            <br/>
            <div className="login-popup-text-small">
                <br/>
                You will permanently lose your voting settings associated with the account.
            </div>
            <br/>
            <div>
                <button id='accept-delete-account-button' className="accept-popup-button popup-button"
                        onClick={async () => {
                            let deleted;
                            try {
                                deleted = await callDeleteUser();
                            } catch (err) {
                                deleted = false;
                            }
                            obj.advancePopup(deleted);
                        }}>Yes
                </button>
                <button id='cancel-delete-account-button' className="cancel-popup-button popup-button" onClick={() => {
                    obj.closePopup()
                }}>Cancel
                </button>
            </div>
        </div>
    }

    handleAutoCloseTopic(event) {
        this.setState({autoCloseTopic: event.target.checked});
    }

    handleAutoJoinVoting(event) {
        this.setState({autoJoinVoting: event.target.checked});
    }

    successDeleteText = function (obj) {
        return <div className="login-popup-text">
            Your ScrumVote account has been successfully deleted.
            <br/>
            <br/>
            <div>
                <button id='ok-delete-account-button' className="accept-popup-button popup-button"
                        onClick={async () => {
                            obj.closePopup();
                            obj.resetPopup();
                            logoutFirebase();
                            obj.props.history.push("/");
                        }}>Ok
                </button>
            </div>
        </div>
    }

    failureDeleteText = function (obj) {
        return <div className="login-popup-text">
            Something went wrong :( Please try again after sometime.
            <br/>
            <br/>
            <div>
                <button id='ok-delete-account-button' className="accept-popup-button popup-button"
                        onClick={async () => {
                            obj.closePopup();
                            obj.resetPopup();
                        }}>Ok
                </button>
            </div>
        </div>
    }
}

const mapState = state => {
    return userAndIsLoadingAndSettings(state);
};

Settings = withRouter(connect(mapState)(Settings));

export default Settings;