import React from "react";
import { userAndIsLoadingAndSettingsAndToken } from "../state/stateMappers";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, InputGroup } from "react-bootstrap";
import roundDone from "../images/round-done-24px.svg";
import roundNotDone from "../images/round-clear-24px.svg";
import removeIcon from "../images/round-delete_forever-24px.svg";
import grayRemoveIcon from "../images/round-delete_forever-disabled-24px.svg";
import crownIcon from "../images/crown.svg";
import crossHairIcon from "../images/crosshair.svg";
import SvTooltip from "./svTooltip";
import { removeUserFromVoting } from "../api/apiClient";

class UserRow extends React.Component {
  render() {
    const domId = "participants-item-" + this.props.index;
    let optionalIcons = this.prepareOptionalIcons(domId);

    return (
      <InputGroup className="mb-1" id={domId}>
        {optionalIcons.length !== 0 && (
          <InputGroup.Prepend>{optionalIcons}</InputGroup.Prepend>
        )}
        <Form.Control
          type="text"
          id={domId + "-username"}
          value={this.props.participant.username}
          disabled={true}
          className="text-right input-group-text"
        />
        {this.props.creatorMode && (
          <InputGroup.Append>{this.renderDeleteIcon(domId)}</InputGroup.Append>
        )}
      </InputGroup>
    );
  }

  renderDeleteIcon(domId) {
    if (this.props.creatorMode) {
      let isThisVotingOwnerRow =
        this.props.voting.creatorUserId === this.props.participant.userId;
      let buttonClasses = isThisVotingOwnerRow
        ? ""
        : "cursor-pointer btn btn-light";
      return (
        <SvTooltip
          elementId={domId + "creator-tooltip"}
          tooltip={
            isThisVotingOwnerRow
              ? "You cannot remove yourself"
              : "Remove this user from voting"
          }
        >
          <InputGroup.Text
            className={buttonClasses}
            id={domId + "-delete"}
            onClick={() => {
              if (!isThisVotingOwnerRow) {
                removeUserFromVoting(
                  this.props.voting.id,
                  this.props.participant.userId
                ).then();
              }
            }}
          >
            <img
              id={domId + "-delete-icon"}
              alt="delete-button"
              src={isThisVotingOwnerRow ? grayRemoveIcon : removeIcon}
            />
          </InputGroup.Text>
        </SvTooltip>
      );
    }
  }

  prepareOptionalIcons(domId) {
    let icons = [];

    if (this.props.voted !== undefined) {
      icons.push(this.prepareVotedImg());
    }
    if (this.props.participant.userId === this.props.settings.userId) {
      icons.push(
        <SvTooltip
          elementId={domId + "you-tooltip"}
          tooltip="This is you"
          key={domId + "_creator"}
        >
          <InputGroup.Text>
            <img
              id={domId + "-creator-icon"}
              alt="creator-icon"
              src={crossHairIcon}
            />
          </InputGroup.Text>
        </SvTooltip>
      );
    }

    if (this.props.participant.userId === this.props.voting.creatorUserId) {
      icons.push(
        <SvTooltip
          elementId={domId + "creator-tooltip"}
          tooltip="This is the creator"
          key={domId + "_you"}
        >
          <InputGroup.Text>
            <img id={domId + "-you-icon"} alt="you-icon" src={crownIcon} />
          </InputGroup.Text>
        </SvTooltip>
      );
    }
    return icons;
  }

  prepareVotedImg() {
    if (this.props.voted !== undefined) {
      let votedImg = this.props.voted ? (
        <img
          id={this.props.participant.userId + "-voted-icon"}
          alt="voted-button"
          src={roundDone}
        />
      ) : (
        <img
          id={this.props.participant.userId + "-not-voted-icon"}
          alt="not-voted-button"
          src={roundNotDone}
        />
      );
      return (
        <SvTooltip
          elementId={this.props.participant.userId + "voted"}
          tooltip="User voted?"
        >
          <InputGroup.Text>{votedImg}</InputGroup.Text>
        </SvTooltip>
      );
    }
  }
}

const mapState = (state) => {
  return userAndIsLoadingAndSettingsAndToken(state);
};

UserRow = withRouter(connect(mapState)(UserRow));

export default UserRow;
