import {combineReducers} from 'redux'
import {
    IS_LOADING,
    LOGIN,
    LOGOUT,
    REFRESH_TOKEN,
    RESET_NEW_TOPIC_ID,
    SET_AUTO_SWITCH_TO_NEW_TOPIC, SET_CURRENT_VOTING_NAME,
    SET_ERROR,
    SET_GLOBAL_RETURN_PATH,
    SET_IS_NEW_USER,
    SET_NEW_TOPIC_ID,
    SET_RETURN_PATH,
    SET_SETTINGS
} from './actions'

export const NO_SETTINGS = "NO_SETTINGS";
export const DEFAULT_TOPIC_ID = "DEFAULT_TOPIC_ID";

function user(state = {}, action) {
    switch (action.type) {
        case LOGIN:
            return Object.assign({}, action.user);
        case LOGOUT:
            return Object.assign({});
        default:
            return state;
    }
}

function token(token = {}, action) {
    switch (action.type) {
        case REFRESH_TOKEN:
            return Object.assign({}, {token: action.token});
        case LOGOUT:
            return {};
        default:
            return token;
    }
}

function isLoading(isLoading = true, action) {
    if (action.type === IS_LOADING) {
        return action.isLoading;
    } else {
        return isLoading;
    }
}

function returnPath(returnPath = "", action) {
    if (action.type === SET_RETURN_PATH) {
        return Object.assign({}, {path: action.returnPath});
    } else {
        return returnPath;
    }
}

function settings(settings = NO_SETTINGS, action) {
    if (action.type === SET_SETTINGS) {
        return Object.assign({}, action.settings);
    } else {
        return settings;
    }
}

function error(error = "", action) {
    if (action.type === SET_ERROR) {
        return action.error;
    } else {
        return error;
    }
}

function globalReturnPath(globalReturnPath = "/", action) {
    if (action.type === SET_GLOBAL_RETURN_PATH) {
        return Object.assign({}, {path: action.globalReturnPath});
    } else {
        return globalReturnPath;
    }
}

function isNewUser(isNewUser = false, action) {
    if (action.type === SET_IS_NEW_USER) {
        return action.isNewUser;
    } else {
        return isNewUser;
    }
}

function currentTopicId(currentTopicId = DEFAULT_TOPIC_ID, action) {
    if (action.type === SET_NEW_TOPIC_ID) {
        return action.topicId;
    } else if (action.type === RESET_NEW_TOPIC_ID) {
        return DEFAULT_TOPIC_ID;
    }
    return currentTopicId;
}

function autoSwitchToNewTopic(autoSwitchToNewTopic = true, action) {
    if (action.type === SET_AUTO_SWITCH_TO_NEW_TOPIC) {
        return action.autoSwitchToNewTopic;
    }
    return autoSwitchToNewTopic;
}

function currentVotingName(votingName = "", action) {
    if (action.type === SET_CURRENT_VOTING_NAME) {
        return action.votingName;
    }
    return votingName;
}

const scrumVoteApp = combineReducers({
    user,
    token,
    isLoading,
    returnPath,
    settings,
    globalReturnPath,
    error,
    isNewUser,
    currentTopicId,
    autoSwitchToNewTopic,
    currentVotingName
});

export default scrumVoteApp;