/*
    Actions
 */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const IS_LOADING = "IS_LOADING";
export const SET_RETURN_PATH = "SET_RETURN_PATH";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_GLOBAL_RETURN_PATH = "SET_GLOBAL_RETURN_PATH";
export const SET_ERROR = "SET_ERROR";
export const SET_IS_NEW_USER = "SET_IS_NEW_USER";
export const SET_AUTO_SWITCH_TO_NEW_TOPIC = "SET_AUTO_SWITCH_TO_NEW_TOPIC";
export const SET_NEW_TOPIC_ID = "SET_NEW_TOPIC_ID";
export const RESET_NEW_TOPIC_ID = "RESET_NEW_TOPIC_ID";
export const SET_CURRENT_VOTING_NAME = "SET_CURRENT_VOTING_NAME";

/*
    Action creators
 */
export function login(user) {
    return {type: LOGIN, user};
}

export function logout() {
    return {type: LOGOUT};
}

export function refreshToken(token) {
    return {type: REFRESH_TOKEN, token};
}

export function isLoading(isLoading) {
    return {type: IS_LOADING, isLoading};
}

export function setReturnPath(returnPath) {
    return {type: SET_RETURN_PATH, returnPath};
}

export function setSettings(settings) {
    return {type: SET_SETTINGS, settings};
}

export function setError(error) {
    return {type: SET_ERROR, error};
}

export function setGlobalReturnPath(globalReturnPath) {
    return {type: SET_GLOBAL_RETURN_PATH, globalReturnPath};
}

export function setIsNewUser(isNewUser) {
    return {type: SET_IS_NEW_USER, isNewUser};
}

export function setCurrentTopicId(topicId) {
    return {type: SET_NEW_TOPIC_ID, topicId}
}

export function resetCurrentTopicId() {
    return {type: RESET_NEW_TOPIC_ID}
}

export function setAutoSwitchToNewTopic(autoSwitchToNewTopic) {
    return {type: SET_AUTO_SWITCH_TO_NEW_TOPIC, autoSwitchToNewTopic};
}

export function setCurrentVotingName(votingName) {
    return {type: SET_CURRENT_VOTING_NAME, votingName};
}