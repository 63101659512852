import React from 'react';
import {userAndIsLoading} from "../state/stateMappers";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import RedirectButton from "../components/redirectButton";
import {ButtonGroup, Col, Collapse, Row} from "react-bootstrap";
import {defaultLogo} from "../components/logo";

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {showInstructions: false};
    }

    render() {
        return <Row className="mt-1">
            <Col/>
            <Col className="main-column">
                {defaultLogo}
                <div/>
                <div>
                    <div className="mt-3 h4">
                    <span>
                        <b>Scrum Vote</b> allows you to organize an online personalized planning session for your team.
                    </span>
                    </div>

                    {!this.props.user &&
                    <div className="text-sm-center mt-3">You should <NavLink id="login-text-link" className="text-link"
                                                                             to="/login">log
                        in</NavLink> before
                        creating or joining a voting.</div>}
                    <ButtonGroup vertical className="mt-4 mb-3 w-75">
                        <RedirectButton id="create-voting-button" target="/create" text="Create voting"/>
                        <RedirectButton id="join-voting-button" target="/join" text="Join voting"/>
                        <RedirectButton className="mt-3" id="instruction-button" text="Instructions"
                                        onClick={() => this.toggleShowInstructions()}/>
                    </ButtonGroup>

                    <Collapse className="ml-2 mr-2 mb-2" in={this.state.showInstructions}>
                        <div>
                        <span>ScrumVote allows you to organize an online personalized planning session for
                        your team. You can
                        play scrum poker remotely with predefined voting options.
                        Before creating an online voting session, please configure your username and voting
                        options.
                        Please note that all data about your voting will be deleted after 24 hours after voting
                        session
                        is created.
                        </span>
                            <span>Scrum vote step by step</span>
                            <div className="mt-3">
                                <ul style={{listStyleType: "none"}}>
                                    <li>1. Create your voting session.</li>
                                    <li>2. Invite your team by showing them the QR code (for mobile app) or send
                                        them join
                                        link
                                    </li>
                                    <li>3. Accept their join request</li>
                                    <li>4. Start the voting</li>
                                    <li>5. Create topics and vote with your team</li>
                                    <li>6. After all topics are closed close voting for summary</li>
                                </ul>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </Col>
            <Col/>
        </Row>
    }

    toggleShowInstructions() {
        this.setState({
            showInstructions: !this.state.showInstructions
        });
    }
}

const mapState = state => {
    return userAndIsLoading(state);
};

Menu = withRouter(connect(mapState)(Menu));

export default Menu;
