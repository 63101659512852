import {loadSettings, logoutFirebase} from "../auth/auth";
import React from "react";
import {userAndIsLoadingAndVotingName} from "../state/stateMappers";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {setGlobalReturnPath} from "../state/actions";
import headerIcon from "../images/headerIcon.png";
import {Button, ButtonGroup, Col, Container, Navbar} from "react-bootstrap";

class Header extends React.Component {

    render() {
        //TODO think how to handle title
        //let titleToDisplay = this.props.headerTitle ? this.props.headerTitle : "";
        let headerText = window.location.toString().match('/voting/') === null ? "" : this.props.currentVotingName;

        return <Navbar bg="dark" variant="dark">
            <Container className="text-left" fluid>
                <Col>
                    <Navbar.Brand>
                        <img id="header-icon" onClick={() => this.props.history.push("/")} alt="" src={headerIcon}
                             className="d-inline-block align-top w-25 cursor-pointer "/>
                    </Navbar.Brand>
                </Col>
                <Col>
                    <div className="text-center h3">{headerText}</div>
                </Col>
                <Col>
                    <Navbar.Collapse className="justify-content-end">
                        <ButtonGroup>
                            <Button id='header-settings-button' onClick={() => this.goToSettings()}>Settings</Button>
                            <Button id='header-logout-button' variant="danger" onClick={() => logoutFirebase()}>Log
                                out</Button>
                        </ButtonGroup>
                    </Navbar.Collapse>
                </Col>
            </Container>
        </Navbar>;
    }

    goToSettings() {
        this.props.dispatch(setGlobalReturnPath(this.props.location.pathname));
        loadSettings().then(() => this.props.history.push("/settings"));
    }
}

const mapState = state => {
    return userAndIsLoadingAndVotingName(state);
};

Header = withRouter(connect(mapState)(Header));

export default Header;