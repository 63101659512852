import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default class SvTooltip extends React.Component {
  render() {
    let elementId = this.props.elementId;
    return (
      <OverlayTrigger
        key={elementId + "trigger"}
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${elementId} -tooltip`}>
            {this.props.tooltip}
          </Tooltip>
        }
      >
        {this.props.children}
      </OverlayTrigger>
    );
  }
}
