import React from "react";
import { userAndIsLoadingAndSettingsAndTokenAndVotingData } from "../state/stateMappers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  isLoading,
  resetCurrentTopicId,
  setCurrentTopicId,
  setCurrentVotingName,
} from "../state/actions";
import ReactLoading from "react-loading";
import {
  getJoinRequestForVoting,
  getVotesForTopic,
  getVoting,
  getVotingTopics,
  loadSummary,
  votingSocketBasePath,
} from "../api/apiClient";
import VotingSummary from "./votingSummary";
import NewVotingForCreator from "../components/newVotingForCreator";
import InProgressVoting from "../components/inProgressVoting";
import RedirectButton from "../components/redirectButton";
import { svLog } from "../commons/logger";
import { Col, Container, Row } from "react-bootstrap";
import { DEFAULT_TOPIC_ID } from "../state/reducers";
import { Client } from '@stomp/stompjs';

class Voting extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(resetCurrentTopicId());

    this.state = {
      votingId: props.match.params.votingId,
      votes: [],
      votingTopics: [],
      stompClient: null
    };

    this.loadVoting = this.loadVoting.bind(this);
    this.handleMessage();
  }

  createInterval() {
    if (!this.state.intervalId) {
      let intervalId = setInterval(() => this.loadDataChain(), 10000);
      this.setState({ intervalId: intervalId });
    }
  }

  removeInterval() {
    if (this.state.intervalId) {
      svLog("Clearing interval: " + this.state.intervalId);
      clearInterval(this.state.intervalId);
    }
  }

  initSockJS() {
    if (this.state.stompClient == null) {

      let client = new Client({
        brokerURL: votingSocketBasePath,
        onConnect: () => {
          client.subscribe("/messages/" + this.state.votingId, message =>
              this.handleMessage(message)
          );
          this.handleMessage();
        },
        heartbeatIncoming: 10000,
        heartbeatOutgoing: 10000,
        reconnectDelay: 5000,
        connectHeaders: { Authorization: this.props.token }
      });

      client.activate();
      this.setState({stompClient: client});
    }
  }

  render() {
    let body = (
      <div className="voting-body">
        {this.state.voting && (
          <Container fluid>
            <Row className="mb-3 mt-2">
              <Col />
            </Row>
            {this.renderVoting()}
            {/*<SockJsClient*/}
            {/*  autoReconnect={true}*/}
            {/*  getRetryInterval={() => 1}*/}
            {/*  url={votingSocketBasePath}*/}
            {/*  topics={["/messages/" + this.state.votingId]}*/}
            {/*  onMessage={(message) => this.handleMessage(message)}*/}
            {/*  onConnect={() => this.removeInterval()}*/}
            {/*  onDisconnect={() => this.createInterval()}*/}
            {/*  onConnectFailure={() => this.createInterval()}*/}
            {/*  headers={{ Authorization: this.props.token }}*/}
            {/*  subscribeHeaders={{ Authorization: this.props.token }}*/}
            {/*  heartbeat={10000}*/}
            {/*/>*/}
          </Container>
        )}
      </div>
    );

    return (
      <div>
        {body}
        {/*<AdSense.Google*/}
        {/*    client='ca-pub-8830903979052028'*/}
        {/*    slot='7806394673'*/}
        {/*    style={{ display: 'block' }}*/}
        {/*    format='auto'*/}
        {/*    responsive='true'*/}
        {/*    layoutKey='-gw-1+2a-9x+5c'*/}
        {/*/>*/}
      </div>
    );
  }

  getReactLoading() {
    return (
      <ReactLoading
        className="loading-icon"
        type={"bars"}
        height={"10%"}
        width={"10%"}
      />
    );
  }

  componentDidMount() {
    this.loadDataChain().then(() => {
      if (this.state.voting) {
        this.props.dispatch(isLoading(false));
      }
    });
    // this.createInterval();
    this.initSockJS();
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    if (this.state.stompClient !== null) {
      this.state.stompClient.deactivate().then();
    }
  }

  handleMessage(msg) {
    svLog(msg + " message received from WS");
    this.loadDataChain().then();
  }

  async loadDataChain() {
    await this.loadVoting()
      .then(() => this.prepareSummary())
      .then(() => this.checkIfIamTheCreator())
      .then(() => this.loadVotingTopics())
      .then(() => this.loadJoinRequests())
      .then(() => this.loadVotes());
  }

  async loadJoinRequests() {
    getJoinRequestForVoting(this.state.voting.id).then((result) =>
      this.setState({ joinRequests: result })
    );
  }

  async loadVotingTopics() {
    return getVotingTopics(this.state.voting.id).then((list) => {
      let votingTopicsLengthBeforeUpdate = this.state.votingTopics.length;
      this.setState({ votingTopics: list });

      if (this.props.currentTopicId === DEFAULT_TOPIC_ID && list.length > 0) {
        this.props.dispatch(setCurrentTopicId(list[0].id));
      }

      if (list.length > votingTopicsLengthBeforeUpdate) {
        if (this.props.autoSwitchToNewTopic) {
          this.props.dispatch(setCurrentTopicId(list[0].id));
        }
      }
    });
  }

  async prepareSummary() {
    if (this.state.voting && this.state.voting.status === "CLOSE") {
      await loadSummary(this.state.voting.id).then((result) =>
        this.setState({ summary: result })
      );
    }
  }

  async loadVotes() {
    if (this.props.currentTopicId !== DEFAULT_TOPIC_ID) {
      await getVotesForTopic(this.props.currentTopicId).then((result) => {
        if (result) {
          this.setState({ votes: result });
        }
      });
    }
  }

  async checkIfIamTheCreator() {
    if (this.state.voting) {
      this.setState({
        isCreator:
          this.state.voting.creatorUserId === this.props.settings.userId,
      });
    }
  }

  async loadVoting() {
    await getVoting(this.state.votingId).then((loadedVoting) => {
      this.setState({ voting: loadedVoting });
      let votingName = this.state.voting ? this.state.voting.name : "";
      this.props.dispatch(setCurrentVotingName(votingName));
    });
  }

  renderVoting() {
    switch (this.state.voting.status) {
      case "NEW":
        return (
          <NewVotingForCreator
            voting={this.state.voting}
            joinRequests={this.state.joinRequests}
            loadVotingFunction={this.loadVoting}
            isCreator={this.state.isCreator}
          />
        );
      case "IN_PROGRESS":
        return (
          <InProgressVoting
            voting={this.state.voting}
            votes={this.state.votes}
            isCreator={this.state.isCreator}
            userId={this.props.settings.userId}
            votingTopics={this.state.votingTopics}
            loadVotingFunction={() => this.loadDataChain()}
            joinRequests={this.state.joinRequests}
          />
        );
      case "CLOSE":
        return this.renderVotingIsClosed();
      default:
        return this.getUnknownStateOfVotingMessage();
    }
  }

  renderVotingIsClosed() {
    return (
      <div className="summary-holder">
        {this.state.summary && <VotingSummary summary={this.state.summary} />}
      </div>
    );
  }

  getUnknownStateOfVotingMessage() {
    return (
      <>
        <div>
          This voting is closed or you do not have access. You should probably
        </div>
        <RedirectButton
          id="fail-read-voting-create-voting-button"
          target="/create"
          text="Create voting"
        />
        or
        <RedirectButton
          id="fail-read-voting-join-voting-button"
          target="/join"
          text="Join voting"
        />
      </>
    );
  }
}

const mapState = (state) => {
  return userAndIsLoadingAndSettingsAndTokenAndVotingData(state);
};

Voting = withRouter(connect(mapState)(Voting));

export default Voting;
