import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {mapStateWithUserAndIsLoading} from "../state/stateMappers";
import {Button} from "react-bootstrap";

class RedirectButton extends React.Component {
    baseClass = " redirect-button";

    render() {
        let className = "no-select border border-dark ";
        if (this.props.classNames) {
            className += this.props.classNames;
        }
        if (!this.props.id || this.props.id === "") {
            throw Error("Every RedirectButton should have a proper id");
        }
        let sizeToUse = this.props.size ? this.props.size : "lg";

        return <Button id={this.props.id} className={className} size={sizeToUse} variant={this.getVariant()}
                       onClick={() => this.combinedOnClick()}>{this.props.text}</Button>

    }

    combinedOnClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
        if (this.props.target) {
            this.props.history.push(this.props.target);
        }

    }

    getVariant() {
        if (this.props.variant) {
            return this.props.variant;
        }
        return "primary";
    }
}

RedirectButton.propTypes = {
    id: PropTypes.string.isRequired,
    target: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

RedirectButton = withRouter(connect(mapStateWithUserAndIsLoading)(RedirectButton));

export default RedirectButton;