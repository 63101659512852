
import React from "react";
import {userAndIsLoading} from "../state/stateMappers";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import googlePlayBadge from "../images/google-play-badge.png";
import SmallLogo from "../components/smallLogo";


class Instructions extends React.Component {

    render() {
        return <>
            <div>
                <SmallLogo/>
                <div/>
                <div className="instructions-holder small-text">
                    <h2>Introduction</h2>
                    <div>ScrumVote allows you to organize an online personalized planning session for your team. You can
                        play scrum poker remotely with predefined voting options.
                        Before creating an online voting session, please configure your username and voting options.
                        Please note that all data about your voting will be deleted after 24 hours after voting session
                        is created.
                    </div>

                    <h2>Scrum vote step by step</h2>
                    <div>
                        <ul style={{listStyleType: "none"}}>
                            <li>1. Create your voting session.</li>
                            <li>2. Invite your team by showing them the QR code (for mobile app) or send them join
                                link
                            </li>
                            <li>3. Accept their join request</li>
                            <li>4. Start the voting</li>
                            <li>5. Create topics and vote with your team</li>
                            <li>6. After all topics are closed close voting for summary</li>
                        </ul>
                    </div>

                    <div className="mobile-store">
                        <a href="https://play.google.com/store/apps/details?id=com.scrumvotemobile" target="_blank"
                           rel="noopener noreferrer">
                            <img className="mobile-badge-google" src={googlePlayBadge} alt="google-play-badge"/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    }
}

const mapState = state => {
    return userAndIsLoading(state);
};

Instructions = withRouter(connect(mapState)(Instructions));

export default Instructions;
