import {isTestOrDev} from "../api/globalConf";

let prodConfig = {
    apiKey: "AIzaSyA5SwspIPZBPujMDthIcl99C5BUmWhWJeM",
    authDomain: "scrumvote-6ec46.firebaseapp.com",
    databaseURL: "https://scrumvote-6ec46.firebaseio.com",
    projectId: "scrumvote-6ec46",
    storageBucket: "scrumvote-6ec46.appspot.com",
    messagingSenderId: "785778264129",
    appId: "1:785778264129:web:25992c46e519e1c7",
    measurementId: "G-RZQ29L6F1Y"
};

let testConfig = {
    apiKey: "AIzaSyAbR_AkxufmfMGX4ZiTyDll4WwRu7SfYYQ",
    authDomain: "scrumvote-test.firebaseapp.com",
    projectId: "scrumvote-test",
    storageBucket: "scrumvote-test.appspot.com",
    messagingSenderId: "196221505261",
    appId: "1:196221505261:web:701f932ed8428a80cd06e4",
    measurementId: "G-97KKR9W6FV"
};
const firebaseConfig = isTestOrDev ? testConfig : prodConfig;

export default firebaseConfig;