export const isDev = process.env.NODE_ENV === 'development';

const baseSitePathDev = 'http://localhost:9999';
const baseSitePathTest = 'https://test.scrum.vote';
const baseSitePathOrigin = window.location.origin;

function getBaseSitePath() {
    if (isDev) {
        if (baseSitePathOrigin.match('localhost')) {
            return baseSitePathDev;
        }
        return baseSitePathTest;
    }
    return baseSitePathOrigin;
}

export const baseSitePath = getBaseSitePath();

export const isTestOrDev = !!baseSitePath.match("test|localhost");

export const baseApiPath = baseSitePath + "/scrumvote";

export const joinSitePath = baseSitePathOrigin + "/join/";
export const basePrivacyPolicyPath = "https://scrum.vote/scrumvote-privacy-policy.html";
export const baseCookiePolicyPath = "https://scrum.vote/scrumvote-cookie-policy.html";