import VoteOptionButton from "./voteOptionButton";
import React from "react";

export default class VotingOptions extends React.Component {
    render() {
        let voteOptionsWithNew = this.props.voteOptions;
        if (voteOptionsWithNew.length === 0 || voteOptionsWithNew[voteOptionsWithNew.length - 1].value !== "") {
            voteOptionsWithNew.push({order: voteOptionsWithNew.length + 1, value: ""});
        }

        let voteOptionsAsButtons = voteOptionsWithNew
            .map(option => <div className="vote-option-delete-button-holder" key={option.order}>
                <VoteOptionButton id={'vote-option-button-' + option.order} key={option.order} order={option.order}
                                  value={option.value}
                                  onDelete={() => {
                                      let arrayOrder = option.order - 1;
                                      voteOptionsWithNew.splice(arrayOrder, 1);
                                      let newOptions = voteOptionsWithNew.map((voteOption, index) => {
                                          voteOption.order = index + 1;
                                          return voteOption;
                                      });
                                      this.props.updateVoteOptions(newOptions);
                                  }}
                                  onChange={(e) => {
                                      let voteOptions = this.props.voteOptions;
                                      voteOptions[option.order - 1].value = e.target.value;
                                      this.props.updateVoteOptions(voteOptions);
                                  }}/></div>);

        return <div className="voting-options">
            <div className="small"> Empty values will be removed</div>
            <div className="vote-values">
                {voteOptionsAsButtons}
            </div>
        </div>;
    }

}