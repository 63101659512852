import React from "react";
import JoinRequestRow from "./joinRequestRow";

export default class JoinRequestList extends React.Component {
  render() {
    let onlyNewRequests = this.props.joinRequests
      ? this.props.joinRequests.filter((candidate) => {
          return candidate.status === "new";
        })
      : undefined;
    if (onlyNewRequests && onlyNewRequests.length > 0) {
      return onlyNewRequests
        .sort((a, b) =>
          a.username.localeCompare(b.username, "en", { sensitivity: "base" })
        )
        .map((request, index) => {
          return (
            <JoinRequestRow
              key={request.id}
              request={request}
              index={index}
              isCreator={this.props.isCreator}
            />
          );
        });
    } else {
      return <div className="small-text">There are no awaiting requests</div>;
    }
  }
}
