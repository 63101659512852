import React from 'react'
import {userAndIsLoadingAndSettings} from "../state/stateMappers";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import RedirectButton from "../components/redirectButton";
import {createJoinRequest, getJoinRequest} from "../api/apiClient";
import ScrumVoteInput from "../components/scrumVoteInput";
import {defaultLoadingBar} from "../commons/loadingBar";
import {Alert, Col, Row} from "react-bootstrap";


class Join extends React.Component {

    constructor(props) {
        super(props);
        this.state = {votingToJoin: "", votingKey: props.match.params.votingKey};

        this.handleVotingToJoinChange = this.handleVotingToJoinChange.bind(this);
        this.joinVoting = this.joinVoting.bind(this);
        this.checkJoinRequest = this.checkJoinRequest.bind(this);
        this.processJoinRequest = this.processJoinRequest.bind(this);
    }

    componentDidMount() {
        let intervalId = setInterval(() => {
            this.checkJoinRequest();
        }, 1000);
        this.setState({interval: intervalId});

        if (this.state.votingKey) {
            this.joinVoting(this.state.votingKey);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    checkJoinRequest() {
        if (this.state.joinRequest) {
            getJoinRequest(this.state.joinRequest.id).then(this.processJoinRequest)
        }
    }

    processJoinRequest(newJoinRequest) {
        if (newJoinRequest.status === 'accepted') {
            let votingId = newJoinRequest.votingId;
            this.props.history.push(`/voting/${votingId}`);
        } else if (newJoinRequest.status === 'rejected') {
            this.setState({wasRejected: true});
            this.setState( {isLoading: false})
        }
    }

    render() {
        let body;
        if (this.state.isLoading) {
            body = <>
                <div className="h4">Awaiting approval</div>
                <span className="bt-3">Voting creator must approve your join request</span>
                <div className="redirect-button-left-div"/>
                {defaultLoadingBar}
                <div className="redirect-button-right-div"/>
            </>;
        } else {
            body = <>
                <div className="h4 mb-2">Ask the creator for voting key or url to join voting.</div>
                {this.state.warnMsg && <Alert className="w-100" variant="danger">{this.state.warnMsg}</Alert>}
                <ScrumVoteInput id='voting-to-join-input' type="text" value={this.state.votingToJoin}
                                onChange={this.handleVotingToJoinChange}
                                placeholder="Voting key..." onKeyPress={this.joinVoting}/>
                <RedirectButton id="join-button" classNames="w-100" onClick={this.joinVoting} text="Join"/></>;
        }
        return <Row>
            <Col/>
            <Col className="mt-5">
                {this.state.wasRejected && <Alert variant="danger">Your previous request was rejected</Alert>}
                {body}
            </Col>
            <Col/>
        </Row>
    }

    joinVoting(votingKey) {
        this.setState({isLoading: true});

        let joinRequestData = {
            username: this.props.settings.username
        };
        joinRequestData.joinName = votingKey ? votingKey : this.state.votingToJoin;

        createJoinRequest(joinRequestData).then((obj) => {
            if (obj === undefined) {
                this.setState({warnMsg: "Unable to join this voting"})
                this.setState({isLoading: false});
            } else {
                this.setState({joinRequest: obj});
            }
        });
    }

    handleVotingToJoinChange(event) {
        // TODO add redux action here and in constructor
        this.setState({votingToJoin: event.target.value})
    }
}

const mapState = state => {
    return userAndIsLoadingAndSettings(state);
};

Join = withRouter(connect(mapState)(Join));

export default Join;