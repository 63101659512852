import React from "react";
import RedirectButton from "./redirectButton";
import { startVoting } from "../api/apiClient";
import QRCode from "qrcode.react";
import { joinSitePath } from "../api/globalConf";
import { Col, ListGroup, Row } from "react-bootstrap";
import ScrumVoteInput from "./scrumVoteInput";
import UserRow from "./userRow";
import JoinRequestList from "./joinRequestsList";

export default class NewVotingForCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {!this.props.isCreator && (
          <Row>
            <Col>
              <span>Voting should be started by creator</span>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="text-left">
            <div className="h4">
              <u>Invite users</u>
            </div>
            <span>To invite users send them the voting join key: </span>

            <ScrumVoteInput
              id="join-key-input"
              type="text"
              value={this.props.voting.joinName}
              className="mt-3"
              disabled={true}
            />
            <span>or url:</span>
            <ScrumVoteInput
              id="join-url-input"
              type="text"
              value={joinSitePath + this.props.voting.id}
              className="mt-1"
              disabled={true}
            />
            <div>
              <div className="users-invite-instructions small-text">
                If they are using the mobile app you can show them QR code:
              </div>
            </div>
            <div className="mt-3 text-center">
              <QRCode
                className="bg-white p-3"
                id="qr-code"
                value={this.props.voting.id}
                size={256}
              />
            </div>
          </Col>
          <Col className="main-column border-column">
            <div className="participants">
              <div className="h4 text-left">
                <u>Current participants</u>
              </div>
              {this.renderCurrentParticipantsPart()}
            </div>
          </Col>
          <Col>
            <div className="text-left">
              <div className="h4">
                <u>Join requests</u>
              </div>
              {!this.props.isCreator && (
                <span>Participants should be added by creator</span>
              )}
              <JoinRequestList
                joinRequests={this.props.joinRequests}
                isCreator={this.props.isCreator}
              />
            </div>
          </Col>
        </Row>
        {this.props.isCreator && (
          <Row>
            <Col />
            <Col>
              <div className="start-voting-button-holder">
                <RedirectButton
                  id="start-voting-button"
                  text="Start voting"
                  classNames="mt-5 w-100"
                  onClick={() => this.startVotingAction()}
                />
              </div>
            </Col>
            <Col />
          </Row>
        )}
      </>
    );
  }

  startVotingAction() {
    startVoting(this.props.voting.id).then(() =>
      this.props.loadVotingFunction()
    );
  }

  renderCurrentParticipantsPart() {
    let userRows = this.props.voting.participants.map((participant, index) => {
      return (
        <UserRow
          key={participant.userId}
          index={index}
          participant={participant}
          voting={this.props.voting}
        />
      );
    });
    return (
      <ListGroup as="ul" className="text-left">
        {userRows}
      </ListGroup>
    );
  }
}
