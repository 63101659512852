import React from 'react';
import PropTypes from 'prop-types'
import {Button, Col, Row} from "react-bootstrap";

class LoginButton extends React.Component {

    constructor(props) {
        super(props);
        this.onClick = PropTypes.func.isRequired;
    }

    render() {
        return <Button variant={"secondary"} size="lg" id={this.props.id} onClick={this.props.onClick}
                       className='w-75 no-select align-self-center border-dark'>
            <Row>
                <Col sm={5}>
                    <div className="float-left"><span><b>{this.props.text}</b></span></div>
                </Col>
                <Col sm={7}>
                    <img className="h-75 rounded float-right" style={{objectFit: "fill"}} src={this.props.logo} alt="login-button-logo"/>
                </Col>
            </Row>
        </Button>;
    }

}

export default LoginButton;