import {isTestOrDev} from "../api/globalConf";


export function svLog(msg) {
    if (isTestOrDev) {
        if (isString(msg)) {
            console.log(msg);
        } else if (msg instanceof Error) {
            console.log(msg.message)
        } else {
            console.log(JSON.stringify(msg));
        }
    }
}

function isString(x) {
    return Object.prototype.toString.call(x) === "[object String]"
}