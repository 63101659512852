import React from "react"
import {Form} from "react-bootstrap";

export default class ScrumVoteInput extends React.Component {

    render() {

        if (!this.props.id || this.props.id === "") {
            throw Error("Every ScrumVoteInput should have a proper id");
        }
        return <>
            <Form id={this.props.id + "-from"} className={this.props.className} autoComplete="off">
                <Form.Group>
                    <Form.Control type={this.props.type} id={this.props.id} value={this.props.value}
                                  onChange={this.props.onChange} placeholder={this.props.placeholder}
                                  disabled={this.props.disabled}
                                  onKeyPress={(e) => this.localOnKeyPres(e)} className="text-center"/>
                </Form.Group>
            </Form>
        </>

    }

    localOnKeyPres(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.props.onKeyPress();
        }
    }
}