import firebaseApp from "firebase/app";
import 'firebase/analytics'

export function actionEvent(action) {
    firebaseApp.analytics().logEvent(action, {action: action});
}

export function navigationBreadcrumb(location) {
}

export function cannotLoadAdd() {
}

export function apiRequestError(code, msg) {
}

export function reportError(code, msg) {
}