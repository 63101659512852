import React from 'react'
import {Col, Row} from "react-bootstrap";

export default class VoteForTopic extends React.Component {

    render() {
        return <Row>
            <Col/>
            <Col className="text-left" sm={4}>{this.props.vote.username}:</Col>
            <Col className="text-right" sm={2}>{this.props.vote.votingOption.value}</Col>
            <Col/>
        </Row>
    }
}