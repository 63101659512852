import firebase from "firebase/app";
import "firebase/auth";
import {isLoading, login, logout, refreshToken, setError, setIsNewUser, setSettings} from "../state/actions";
import {scrumVoteStore} from "../state/scrumVoteStore";
import {createSettings, getSettingsApi, UNABLE_TO_LOAD_SETTINGS} from "../api/apiClient";
import firebaseConfig from "./firebaseConf";
import {svLog} from "../commons/logger";
import history from "../navigation/history";

export function getCurrentToken() {
    let state = scrumVoteStore.getState();
    return state.token;
}

export const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();

const googleProvider = new firebase.auth.GoogleAuthProvider();

export function loginFirebaseWithGoogle() {
    scrumVoteStore.dispatch(isLoading(true));

    firebaseAppAuth.signInWithPopup(googleProvider).then(async (result) => {
        let isNewUser = result.additionalUserInfo.isNewUser;
        scrumVoteStore.dispatch(setIsNewUser(isNewUser));
        if (isNewUser) {
            await createNewUserSettings(result.user.displayName);
        } else {
            return forceRefreshToken();
        }
    }).then(() => {
        processReturnPath();
    }).catch(error => {
        svLog(error);
    });
}

export function loginFirebaseAnonymously(username) {
    scrumVoteStore.dispatch(isLoading(true));
    firebaseAppAuth.signInAnonymously().then(async (result) => {
        let isNewUser = result.additionalUserInfo.isNewUser;
        scrumVoteStore.dispatch(setIsNewUser(isNewUser));
        await createNewUserSettings(username, true);
        return forceRefreshToken();
    }).then(() => {
        processReturnPath();
    }).catch(error => {
        svLog(error);
    });
}

function processReturnPath() {
    scrumVoteStore.dispatch(isLoading(false));
    let state = scrumVoteStore.getState();
    history.push(state.returnPath !== "" ? state.returnPath.path : "/");
}

export function logoutFirebase() {
    scrumVoteStore.dispatch(isLoading(true));

    firebaseAppAuth.signOut().then(() => {
        scrumVoteStore.dispatch(logout());
    }).catch(error => {
        svLog(error);
    }).then(() => {
        scrumVoteStore.dispatch(isLoading(false));
    });
}

async function forceRefreshToken(user) {

    if (firebaseAppAuth.currentUser || user) {

        let currentUser = firebaseAppAuth.currentUser ? firebaseAppAuth.currentUser : user;
        scrumVoteStore.dispatch(login(currentUser));
        await currentUser.getIdToken(true).then(function (idToken) {
            scrumVoteStore.dispatch(refreshToken(idToken));
        });
    }
}

async function createNewUserSettings(username, isAnonymous) {
    await forceRefreshToken();
    let defaultSettings = JSON.parse('{ "username":"", "config": {"votingOptions" :[{"order":"1", "value":"2"},{"order":"2", "value":"4"},{"order":"3", "value":"8"},{"order":"4", "value":"16"},{"order":"5", "value":"24"},{"order":"6", "value":"32"}],"votingSettings": {"autoCloseTopic": false, "autoJoinVoting": false}}}');
    defaultSettings.username = username;
    if (isAnonymous) {
        defaultSettings.anonymous = true;
    }

    await createSettings(defaultSettings);
    await loadSettings();
}

export async function loadSettings(isAnonymous, username) {
    await getSettingsApi().then((data) => {
        if (data !== UNABLE_TO_LOAD_SETTINGS) {
            let text = JSON.stringify(data);
            if (text) {
                scrumVoteStore.dispatch(setSettings(JSON.parse(text)));
            }
        } else if (data === UNABLE_TO_LOAD_SETTINGS) {
            let usernameToUse = isAnonymous ? "Anonymous" + getRandom() : username;
            createNewUserSettings(usernameToUse, isAnonymous)
        } else {
            scrumVoteStore.dispatch(setError(UNABLE_TO_LOAD_SETTINGS));
            history.push("/error");
            scrumVoteStore.dispatch(isLoading(false));
        }
    })
}

function getRandom() {
    let min = Math.ceil(100);
    let max = Math.floor(1000000);
    return Math.floor(Math.random() * (max - min)) + min;
}

function tokenInterval() {
    svLog('Running interval')
    if (scrumVoteStore.getState().user) {
        forceRefreshToken().then();
    }
}

function init() {
    firebaseAppAuth.onAuthStateChanged(async function (user) {
        scrumVoteStore.dispatch(isLoading(true));
        try {
            if (user) {
                await forceRefreshToken(user);
                if (!scrumVoteStore.getState().isNewUser) {
                    await loadSettings(user.isAnonymous, user.displayName);
                }
            } else {
                svLog("User should not be undefined")
            }
        } catch (e) {
            svLog(e);
        }
        scrumVoteStore.dispatch(isLoading(false));
    });

    setInterval(() => tokenInterval(), 1000 * 60 * 15);
}

init();