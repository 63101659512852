export const mapStateWithUserAndIsLoading = state => {
    return userAndIsLoading(state);
};

export function userAndIsLoading(state) {
    let user = exportUser(state);
    return Object.assign(user, {isLoading: state.isLoading, globalReturnPath: state.globalReturnPath});
}

export function userAndIsLoadingAndVotingName(state) {
    let part = userAndIsLoading(state);
    return Object.assign(part, {currentVotingName: state.currentVotingName})
}

export function userAndIsLoadingAndReturnPath(state) {
    let part = userAndIsLoading(state);
    return Object.assign(part, {returnPath: state.returnPath});
}

export function userAndIsLoadingAndSettingsAndReturnPath(state) {
    let part = userAndIsLoadingAndSettings(state);
    return Object.assign(part, {returnPath: state.returnPath});
}

export function userAndIsLoadingAndSettings(state) {
    let part = userAndIsLoading(state);
    return Object.assign(part, {settings: state.settings});
}

export function userAndIsLoadingAndSettingsAndToken(state) {
    let part = userAndIsLoadingAndSettings(state);
    return Object.assign(part, {token: state.token.token});
}

export function userAndIsLoadingAndSettingsAndTokenAndVotingData(state) {
    let part = userAndIsLoadingAndSettingsAndToken(state);
    let partWithIndex = Object.assign(part, {currentTopicId: state.currentTopicId});
    return Object.assign(partWithIndex, {autoSwitchToNewTopic: state.autoSwitchToNewTopic});
}


export function userAndIsLoadingAndSettingsAndError(state) {
    let partOfState = userAndIsLoadingAndSettings(state);
    return Object.assign(partOfState, exportError(state));
}

export function exportUser(state) {
    let user = state.user;
    if (Object.entries(user).length === 0 && user.constructor === Object) {
        user = undefined;
    }

    return {
        user: user,
        isNewUser: state.isNewUser
    };
}

export function exportError(state) {
    return {error: state.error};
}